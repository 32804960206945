<template>
  <div ref="dPlayerEl"></div>
</template>

<script lang="ts">
import DPlayer from "dplayer";
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dPlayerEl = ref<HTMLElement>();

    onMounted(async () => {
      new DPlayer({
        container: dPlayerEl.value as HTMLElement,
        screenshot: true,
        video: {
          url: props.url
        }
      });
    });

    return { dPlayerEl };
  }
});
</script>
