<template>
  <div class="card">
    <h5 class="card-body">{{ $route.query.title }}</h5>
  </div>

  <DPlayer :url="$route.query.url" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DPlayer from "@/components/DPlayer.vue";

export default defineComponent({
  components: {
    DPlayer
  }
});
</script>
